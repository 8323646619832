import Vue from 'vue';
import ct from 'countries-and-timezones';

Vue.filter('currency', function (value, baseCurrency, quoteCurrency = false, exchangeRate = false) {

    const timezone = ct.getTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone);
    const country = timezone.countries[0];

    // Country to region map
    const countryToRegionMap = {
        CH: 'de-CH',
        US: 'en-US',
        DE: 'de-DE',
        FR: 'fr-FR',
        GB: 'en-GB',
        BE: 'fr-BE',
    };

    const region = countryToRegionMap[country] || 'en-US'; // Fallback to 'en-US' if country not in the map

    let price;

    // Rounding function
    const round = (value, precision = 100) => Math.floor(value * precision) / precision;

    // Handle cases where exchange rate or quote currency are not provided
    if (!quoteCurrency || !exchangeRate || quoteCurrency === baseCurrency) {
        price = new Intl.NumberFormat(region, {
            style: 'currency',
            currency: baseCurrency,
        }).format(value);
    } else {
        // Convert the value with the exchange rate
        const amount = round(value * exchangeRate);
        price = new Intl.NumberFormat(region, {
            style: 'currency',
            currency: quoteCurrency,
        }).format(amount);
    }

    return price;
});
