<template>
	<header class="hero-header">
		<div class="hero-header-container">
			<img
				:alt="imgDescription"
				class="hero-header-img"
				src="@/assets/img/heroes-sections/kenya-balloon-game-safari-travel-afrique.jpg"
			/>
			<div class="wrapper">
				<div class="container">
					<div class="header--service--form">
						<SegmentedControl
							v-if="false"
							@clickBtn="toggleForm"
							class="mb-4"
							:text1="$t('page.home.header.tailored_trips')"
							:text2="$t('page.home.header.group_tours')"
						/>

						<div
							class="hero-header-txt-container"
							v-if="showTailored"
						>
							<h1 class="hero-header-title">
								{{ headingTxt }}
							</h1>
							<p class="hero-header-description">
								{{ subHeadingTxt }}
							</p>
							<InputSearch
								@click.native="$emit('openSearch')"
								class="border border-grey-base rounded-xl"
							/>
							<router-link
								to="/destinations"
								v-if="true"
								class="btn-text mt-4"
								v-t="'cta.not_sure_discover'"
							/>
						</div>
						<div
							class="hero-header-txt-container"
							v-if="showPackage"
						>
							<h1
								class="hero-header-title"
								v-t="'page.home.header.group_tours.title'"
							/>
							<p
								class="hero-header-description"
								v-t="'page.home.header.group_tours.text'"
							/>
							<InputSearch
								service="tours"
								@click.native="$emit('openSearch')"
								class="border border-grey-base rounded-xl"
							/>
							<router-link
								to="/destinations"
								v-if="true"
								class="btn-text mt-4"
								v-t="'cta.not_sure_discover'"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	</header>
</template>
<script>
import InputSearch from "@/components/inputs/InputSearch";
import SegmentedControl from "./tabs/SegmentedControl.vue";
export default {
	name: "HeroHeader",
	components: { InputSearch, SegmentedControl },
	props: {
		imgName: {
			type: String,
			default: "travel-afrique-natures-valley-south-africa.jpg",
		},
		imgDescription: {
			type: String,
		},
		headingTxt: {
			type: String,
		},
		subHeadingTxt: {
			type: String,
		},
		actionText: {
			type: String,
			default: "Action text",
		},
		route: {
			type: String,
			default: "/",
		},
	},
	data() {
		return {
			showPackage: false,
			showTailored: true,
			tabItems: [
				{ name: "Tailored trips", isActive: true },
				{ name: "Tour", isActive: false },
			],
		};
	},
	methods: {
		toggleForm(btn) {
			if (btn === "first") {
				this.showPackage = false;
				this.showTailored = true;
			}
			if (btn === "second") {
				this.showPackage = true;
				this.showTailored = false;
			}
			this.$emit("service", {
				tours: this.showPackage,
				tailored: this.showTailored,
			});
		},
	},
};
</script>
<style scoped>
.hero-header {
	@apply w-full h-120 xs:h-160 xl:h-70vh;
}

.hero-header-container {
	@apply relative w-full h-full xs:px-4 md:px-12 overflow-hidden;
}

.hero-header-overlay {
	@apply absolute top-0 left-0 z-10 w-full h-full bg-black-base opacity-0;
}

.hero-header-img {
	@apply hidden xs:block absolute object-cover object-bottom left-0 top-0 w-full h-full;
}

.wrapper {
	@apply relative h-full pt-20;
}

.container {
	@apply w-full max-w-screen-xl md:max-w-screen-md;
	@apply relative left-1/2 transform -translate-x-1/2 h-full;
	@apply bg-gradient-to-br from-yellow-lighter to-green-lighter xs:from-transparent xs:to-transparent;
}
.header--service--form {
	@apply z-30 max-w-screen-xl sm:max-w-lg w-full;
	@apply xs:bg-white px-4 xs:p-12 xs:rounded-xl;
	@apply xs:shadow-xl xs:border xs:border-grey-base;
	@apply absolute left-0 top-1/2 transform -translate-y-1/2;
}

.hero-header-txt-container {
	@apply sm:max-w-lg;
}

.hero-header-title {
	@apply font-sans text-2xl font-semibold text-black-base text-left inline-block;
}

.hero-header-description {
	@apply w-full mb-4 font-sans text-base text-black-base mt-2 md:mb-4;
}

.btn-text {
	@apply font-semibold text-teal-dark text-sm underline;
	@apply hover:opacity-70;
	@apply transition-all ease-in-out duration-300;
	@apply inline-block;
}
</style>
